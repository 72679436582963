import { Helmet } from "react-helmet";
import { website_name } from "../../database/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import useIsAdmin, {
  useFans,
  useFeatured,
  useMusic,
  usePhotos,
  useVideos,
} from "../../firebase/fbQueries";
import { db } from "../../firebase/config";
import {
  faCirclePause,
  faCirclePlay,
} from "@fortawesome/free-regular-svg-icons";
import { useState } from "react";
import { useRef } from "react";
import PhotoCard from "../common/PhotoCard";
import { faSpotify, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

function About() {
  const isAdmin = useIsAdmin(db);

  const profile =
    "https://firebasestorage.googleapis.com/v0/b/danielospid.appspot.com/o/users%2Fdanielospid%2Fprofile.png?alt=media&token=17ab58a3-af72-4435-9e3d-24bc5e245bd7";

  const birthDate = new Date(2001, 7, 1, 12, 0);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDifference = today.getMonth() - birthDate.getMonth();
  const dayDifference = today.getDate() - birthDate.getDate();
  if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
    age--;
  }

  const featured = useFeatured(db);
  const photos = usePhotos(db);
  const music = useMusic(db);
  const videos = useVideos(db);
  const fans = useFans(db);

  const [play, setPlay] = useState(false);
  const audioRef = useRef(null);

  const handlePlay = () => {
    if (!play) {
      if (audioRef.current) {
        audioRef.current.play();
        setPlay(true);
      }
    } else {
      audioRef.current.pause();
      setPlay(false);
    }
  };

  const handleEnded = () => {
    setPlay(false);
  };

  const pinMusic = featured.find((music) => music.id === "music");

  return (
    <>
      <Helmet>
        <title>Sobre Mí - {website_name}</title>
      </Helmet>

      <section className="section">
        <div className="container">
          <div className="row-title">
            <h2>Sobre Mí</h2>
            <div className="flex-action"></div>
          </div>
          <div className="about">
            <div className="about-header">
              <div className="about-profile">
                <img src={profile} alt="Foto de Perfil de Daniel Ospid" />
              </div>
              <div className="about-data">
                <div className="name">Daniel Ospid</div>
                <div className="username">@danielospid</div>
                <div className="fans">{`${fans.length} Fanáticos`}</div>
              </div>
              <div className="about-music">
                {pinMusic && (
                  <div className="about-music-body">
                    <div
                      className="about-music-bg"
                      style={{ backgroundImage: `url(${pinMusic.cover})` }}
                    ></div>
                    <div className="about-music-cover">
                      <img
                        src={pinMusic.cover}
                        alt={"Portada de " + pinMusic.title}
                      />
                      <button
                        onClick={handlePlay}
                        title={!play ? "Play" : "Pausa"}
                        aria-label={!play ? "Play" : "Pausa"}
                      >
                        <FontAwesomeIcon
                          icon={!play ? faCirclePlay : faCirclePause}
                          size="3x"
                        />
                      </button>
                    </div>
                    <div className="about-music-data">
                      <h4 title={pinMusic.title}>{pinMusic.title}</h4>
                      <span>{`${pinMusic.type} • ${new Date(
                        pinMusic.date.seconds * 1000
                      ).toLocaleDateString("es", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      })}`}</span>
                      {(pinMusic.spotify || pinMusic.youtube) && (
                        <div className="about-music-links">
                          {pinMusic.spotify && (
                            <a
                              href={pinMusic.spotify}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="btn-listen icon"
                              title={
                                'Escuchar "' + pinMusic.title + '" en Spotify'
                              }
                            >
                              <FontAwesomeIcon icon={faSpotify} size="lg" />
                            </a>
                          )}
                          {pinMusic.youtube && (
                            <a
                              href={`${pinMusic.youtube}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              title={'Ver "' + pinMusic.title + '" en YouTube'}
                              className="btn-listen icon"
                            >
                              <FontAwesomeIcon icon={faYoutube} size="lg" />
                            </a>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="about-content">
              <div className="about-column">
                <article>
                  <h2>Biografía</h2>
                  <p>
                    Daniel Ospid, nacido el 1 de agosto de 2001 en San Salvador,
                    El Salvador, es un cantautor independiente que ha encontrado
                    en la música su verdadera vocación. Desde una edad temprana,
                    Daniel desarrolló una fuerte conexión con la música, lo que
                    lo llevó a aprender de manera autodidacta a escribir
                    canciones y producir sus propios temas. Comenzó tocando el
                    teclado y, más recientemente, ha ampliado su talento
                    aprendiendo a tocar la guitarra eléctrica. Su habilidad para
                    combinar géneros como el pop, rock y trap lo distingue,
                    ofreciendo una fusión única y emocional en cada una de sus
                    composiciones.
                  </p>
                  <p>
                    Aunque estudia una carrera universitaria en un campo ajeno a
                    la música, Daniel nunca ha perdido de vista su verdadero
                    sueño. A pesar de las limitaciones y de no contar con el
                    apoyo total de su entorno para dedicarse a la música desde
                    un inicio, ha encontrado maneras de hacer coexistir sus
                    estudios con su pasión musical. Esta dualidad también lo
                    llevó a explorar otras habilidades, como la programación,
                    gracias a la cual ha desarrollado su propio sitio web y
                    otros proyectos.
                  </p>
                  <p>
                    Daniel se caracteriza por su persistencia y su enfoque en
                    seguir su carrera musical a su propio ritmo, decidido a
                    superar cualquier obstáculo. Aunque ha lanzado música de
                    forma limitada hasta ahora, está comprometido a avanzar en
                    su trayectoria musical. Sus experiencias de vida, sus
                    emociones y su profunda sensibilidad artística son evidentes
                    en cada una de sus letras, posicionándolo como una promesa
                    dentro de la escena musical emergente en El Salvador.
                    Mientras continúa trabajando en sus futuros lanzamientos,
                    Daniel Ospid sigue cultivando su estilo, siempre con la
                    vista puesta en compartir su historia y emociones con el
                    mundo.
                  </p>
                </article>
                <article>
                  <h2>Carrera Musical</h2>
                  <p>
                    Daniel Ospid escribió su primera canción en 2019, a sus 18
                    años de edad, como parte de un proyecto escolar sobre las
                    ondas sonoras. Aunque esta canción aún no ha sido publicada,
                    está en proceso de producción y se espera que forme parte de
                    su primer álbum, el cual podría lanzarse entre 2026 y 2027.
                    Actualmente, Daniel combina su carrera musical con sus
                    estudios universitarios, lo que le ha impedido dedicar todo
                    el tiempo que quisiera a la música, resultando en
                    lanzamientos esporádicos.
                  </p>
                  <p>
                    En 2021, lanzó su primer EP titulado{" "}
                    <i title="Drop Tracks, EP">Drop Tracks</i>, una colección de
                    seis canciones instrumentales que marcaban el inicio de su
                    camino en la producción musical. Sin embargo, dos años
                    después, y tras un rendimiento modesto, decidió retirarlo de
                    las plataformas. El EP había sido lanzado bajo un seudónimo
                    que más tarde abandonó debido a problemas de identidad
                    artística y a la existencia de otro artista con el mismo
                    nombre. A pesar de su retiro, Drop Tracks sigue disponible
                    exclusivamente en la tienda oficial de Daniel Ospid.
                  </p>
                  <p>
                    A finales de 2024, una ola de inspiración llevó a Daniel a
                    escribir varias canciones mientras atravesaba un momento de
                    gran motivación personal. Actualmente, está trabajando en
                    nuevas producciones que planea lanzar en 2025. Estos
                    lanzamientos serán sus primeros bajo el nombre de Daniel
                    Ospid, con un estilo que fusiona rock con pop. Ha estado
                    componiendo, produciendo y dirigiendo sus próximos
                    lanzamientos, con una cuidadosa planificación para su
                    estreno el siguiente año.
                  </p>
                  <p>
                    El 14 de febrero de 2025, Daniel lanzará un sencillo de
                    género rock, acompañado de dos versiones del mismo: una
                    versión original y otra con variaciones alternativas. El
                    sencillo también contará con un video musical oficial,
                    mientras que las versiones alternativas tendrán sus propios
                    vídeos de audio. Además, tiene planeado otro lanzamiento
                    importante para finales de mayo de 2025.
                  </p>
                </article>
                <article>
                  <h2>Galería</h2>
                  <div className="photos-grid">
                    {photos &&
                      photos
                        .sort((a, b) => b.date - a.date)
                        .slice(0, 6)
                        .map((photo, index) => (
                          <>
                            <PhotoCard key={index} {...photo} />
                          </>
                        ))}
                  </div>
                  <div className="photos-action">
                    <Link to="/photos" className="button primary-button">
                      Ver Más Fotografías
                    </Link>
                  </div>
                </article>
                <article>
                  <h2>Discografía</h2>
                  <div className="table-row">
                    <table>
                      <thead>
                        <th>Título</th>
                        <th>Formato</th>
                        <th>Fecha</th>
                        <th>Géneros</th>
                        <th>Artistas</th>
                        <th>Compositores</th>
                        <th>Productores</th>
                        <th>Discográfica</th>
                      </thead>
                      <tbody>
                        {music
                          .sort((a, b) => b.date - a.date)
                          .map((music, index) => (
                            <>
                              {music.show || isAdmin ? (
                                <tr key={index}>
                                  <td>{music.title && music.title}</td>
                                  <td>{music.type && music.type}</td>
                                  <td>
                                    {music.date &&
                                      new Date(
                                        music.date.seconds * 1000
                                      ).toLocaleDateString("es", {
                                        day: "2-digit",
                                        month: "short",
                                        year: "numeric",
                                      })}
                                  </td>
                                  <td>
                                    {music.genre && music.genre.join(", ")}
                                  </td>
                                  <td>
                                    {music.artists && music.artists.join(", ")}
                                  </td>
                                  <td>
                                    {music.composers &&
                                      music.composers.join(", ")}
                                  </td>
                                  <td>
                                    {music.producers &&
                                      music.producers.join(", ")}
                                  </td>
                                  <td>{music.label && music.label}</td>
                                </tr>
                              ) : null}
                            </>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </article>
                <article>
                  <h2>Videografía</h2>
                  <div className="table-row">
                    <table>
                      <thead>
                        <th>Título</th>
                        <th>Formato</th>
                        <th>Fecha</th>
                        <th>Artistas</th>
                        <th>Director</th>
                      </thead>
                      <tbody>
                        {videos
                          .sort((a, b) => b.date - a.date)
                          .map((video, index) => (
                            <>
                              {(video.show || isAdmin) && (
                                <tr key={index}>
                                  <td>{video.title && video.title}</td>
                                  <td>{video.format && video.format}</td>
                                  <td>
                                    {video.date &&
                                      new Date(
                                        video.date.seconds * 1000
                                      ).toLocaleDateString("es", {
                                        day: "2-digit",
                                        month: "short",
                                        year: "numeric",
                                      })}
                                  </td>
                                  <td>
                                    {video.artists && video.artists.join(", ")}
                                  </td>
                                  <td>{video.director && video.director}</td>
                                </tr>
                              )}
                            </>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </article>
              </div>
              <div className="about-aside">
                <div className="block-list">
                  <div className="title">Información Personal</div>
                  <ul className="list">
                    <li className="item">
                      <b>Nacimiento: </b>1 de agosto de 2001
                      {` (edad ${age} años)`}
                    </li>
                    <li className="item">
                      <b>Residencia: </b>San Salvador
                    </li>
                    <li className="item">
                      <b>Nacionalidad: </b>Salvadoreña
                    </li>
                    <li className="item">
                      <b>Lengua Materna: </b>Español
                    </li>
                  </ul>
                </div>
                <div className="block-list">
                  <div className="title">Características Físicas</div>
                  <ul className="list">
                    <li className="item">
                      <b>Altura: </b>1,67 m
                    </li>
                    <li className="item">
                      <b>Ojos: </b>Café
                    </li>
                    <li className="item">
                      <b>Cabello: </b>Negro
                    </li>
                  </ul>
                </div>
                <div className="block-list">
                  <div className="title">Información Profesional</div>
                  <ul className="list">
                    <li className="item">
                      <b>Ocupación: </b>Cantante, Compositor, Músico
                    </li>
                    <li className="item">
                      <b>Seudónimo: </b>Daniel Ospid
                    </li>
                    <li className="item">
                      <b>Géneros: </b>Pop, Rock, Trap, Pop en Español
                    </li>
                    <li className="item">
                      <b>Instrumentos: </b>Voz, Teclado, Guitarra Eléctrica
                    </li>
                    <li className="item">
                      <b>Discográfica: </b>Independiente
                    </li>
                    <li className="item">
                      <span className="link">
                        <b>Tienda: </b>
                        <a
                          href="https://shop.danielospid.com"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          shop.danielospid.com
                        </a>
                        <FontAwesomeIcon
                          icon={faArrowUpRightFromSquare}
                          size="sm"
                        />
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {pinMusic && (
              <div className="about-assets">
                <audio
                  src={pinMusic.song}
                  ref={audioRef}
                  onEnded={handleEnded}
                  controls={true}
                  controlsList="nodownload"
                ></audio>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
export default About;
