function PhotoCard(props) {
  return (
    <>
      {props.show && (
        <div className="photo-card" title={props.alt}>
          <img alt={props.alt} src={props.image} className="photo-image" />
          <div className="photo-screen"></div>
        </div>
      )}
    </>
  );
}
export default PhotoCard;
