import {
  faBitcoin,
  faBluesky,
  faDeezer,
  faDiscord,
  faFacebook,
  faFlickr,
  faImdb,
  faInstagram,
  faItunesNote,
  faNapster,
  faPatreon,
  faPaypal,
  faPinterestP,
  faShopify,
  faSnapchat,
  faSoundcloud,
  faSpotify,
  faSteam,
  faTelegram,
  faThreads,
  faTiktok,
  faTumblr,
  faTwitch,
  faTwitter,
  faVk,
  faWhatsapp,
  faXTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSocials } from "../../firebase/fbQueries";
import { db } from "../../firebase/config";

function Socials() {
  const socials = useSocials(db);

  const iconMap = {
    threads: faThreads,
    facebook: faFacebook,
    instagram: faInstagram,
    tiktok: faTiktok,
    twitter: faTwitter,
    youtube: faYoutube,
    tumblr: faTumblr,
    twitch: faTwitch,
    discord: faDiscord,
    telegram: faTelegram,
    snapchat: faSnapchat,
    bluesky: faBluesky,
    x: faXTwitter,
    vk: faVk,
    whatsapp: faWhatsapp,
    pinterest: faPinterestP,
    flickr: faFlickr,
    steam: faSteam,
    imdb: faImdb,
    paypal: faPaypal,
    patreon: faPatreon,
    shopify: faShopify,
    bitcoin: faBitcoin,
    spotify: faSpotify,
    napster: faNapster,
    itunes: faItunesNote,
    deezer: faDeezer,
    soundcloud: faSoundcloud,
  };

  return (
    <div className="footer-socials">
      <ul className="social-icons">
        {socials
          .sort((a, b) => a.order - b.order)
          .map(
            (social, index) =>
              social.show && (
                <li className="social" key={index} order={social.order}>
                  <a
                    href={`https://${social.link}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={social.title}
                    aria-label={`Abrir ${social.title} en nueva página`}
                  >
                    <FontAwesomeIcon icon={iconMap[social.icon]} size="xl" />
                  </a>
                </li>
              )
          )}
      </ul>
    </div>
  );
}
export default Socials;
