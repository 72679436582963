import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { website_name } from "../../database/config";
import { useBlog } from "../../firebase/fbQueries";
import { db } from "../../firebase/config";

function Article() {
  const blog = useBlog(db);
  const { path } = useParams();
  const article = blog.find((article) => article.path === path);

  return (
    <>
      {article && (
        <>
          <Helmet>
            <title>
              {article.title} - {website_name}
            </title>
          </Helmet>

          <section className="section">
            <div className="container">
              <div className="row-title">
                <h2>{article.title}</h2>
              </div>
              <div className="blog">
                <div className="column">
                  <article>
                    <Link to={`/article/${article.path}`}>
                      <img src={article.banner} alt="" />
                      <h1>
                        {article.title}
                        <br />
                        <small>
                          {article.author} -{" "}
                          {new Date(
                            article.date.seconds * 1000
                          ).toLocaleDateString("es", {
                            day: "2-digit",
                            month: "long",
                            year: "numeric",
                          })}
                        </small>
                      </h1>
                      <p>{article.summary}</p>
                      <div className="action">
                        <div></div>
                        <Link
                          to={`/article/${article.path}`}
                          className="button primary-button"
                        >
                          Leer Artículo
                        </Link>
                      </div>
                    </Link>
                  </article>
                  <code>Articulo de prueba, no es la versión final.</code>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
}
export default Article;
