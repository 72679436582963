import { Helmet } from "react-helmet";
import { website_name } from "../../database/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useBlog } from "../../firebase/fbQueries";
import { db } from "../../firebase/config";

export default function Blog() {
  const blog = useBlog(db);

  return (
    <>
      <Helmet>
        <title>Blog - {website_name}</title>
      </Helmet>

      <section className="section">
        <div className="container">
          <div className="row-title">
            <h2>Blog</h2>
            <div className="flex-action">
              <div className="select-wrapper">
                <input type="text" placeholder="Buscar por título..." />
                <FontAwesomeIcon icon={faMagnifyingGlass} size="sm" />
              </div>
            </div>
          </div>
          <div className="blog">
            <div className="column">
              {blog
                .sort((a, b) => b.date - a.date)
                .map((article, index) => (
                  <article key={index}>
                    <Link to={`/article/${article.path}`}>
                      <img src={article.banner} alt="" />
                      <h1>
                        {article.title}
                        <br />
                        <small>
                          {article.author} -{" "}
                          {new Date(
                            article.date.seconds * 1000
                          ).toLocaleDateString("es", {
                            day: "2-digit",
                            month: "long",
                            year: "numeric",
                          })}
                        </small>
                      </h1>
                      <p>{article.summary}</p>
                      <div className="action">
                        <div></div>
                        <Link
                          to={`/article/${article.path}`}
                          className="button primary-button"
                        >
                          Leer Artículo
                        </Link>
                      </div>
                    </Link>
                  </article>
                ))}
            </div>
            <div className="aside"></div>
          </div>
        </div>
      </section>
    </>
  );
}
