import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import { website_name } from "../../database/config";
import useIsAdmin, { useLyrics } from "../../firebase/fbQueries";
import { db } from "../../firebase/config";
import NoFound from "./NoFound";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

function LyricPage() {
  const isAdmin = useIsAdmin(db);
  const { path } = useParams();
  const lyrics = useLyrics(db);

  const lyricSelected = lyrics.find(
    (lyric) => lyric.id === path || lyric.path === path
  );

  const htmlStringContent = lyricSelected ? lyricSelected.content : "";

  return (
    <>
      {lyricSelected ? (
        <>
          {lyricSelected.show || isAdmin ? (
            <>
              <Helmet>
                <title>
                  {lyricSelected.title} - {website_name}
                </title>
              </Helmet>

              <section className="section">
                <div className="container">
                  <div className="lyrics-page">
                    <section className="lyrics">
                      <h1>{lyricSelected.title}</h1>
                      <div
                        className="lyric"
                        dangerouslySetInnerHTML={{ __html: htmlStringContent }}
                      ></div>
                    </section>

                    {lyricSelected.composers && (
                      <section className="composers">
                        <h3>
                          {lyricSelected.composers.length > 1
                            ? "Compositores"
                            : "Compositor"}
                        </h3>
                        <p>{lyricSelected.composers.join(", ")}</p>
                      </section>
                    )}
                  </div>
                </div>
              </section>
            </>
          ) : (
            <>
              <h1>No tienes acceso a está página.</h1>
              <p>Esta letra aun no está publicada.</p>
            </>
          )}
        </>
      ) : (
        <>
          <NoFound />
        </>
      )}
    </>
  );
}
export default LyricPage;
