import { Helmet } from "react-helmet";
import { website_name } from "../../database/config";

function Terms() {
  return (
    <>
      <Helmet>
        <title>Términos & Condiciones - {website_name}</title>
        <meta
          property="og:url"
          content="https://danielospid.com/policies/terms"
        />
      </Helmet>

      <section className="section">
        <div className="container">
          <article className="article">
            <h1>Términos & Condiciones</h1>
            <hr />
            <p>
              Bienvenido al sitio web de Daniel Ospid. Al utilizar este sitio
              web, usted está aceptando los siguientes términos & condiciones:
            </p>
            <p>
              <b>Propiedad del sitio web: </b>
              Este sitio web y todo su contenido, incluyendo, pero no limitado a
              textos, imágenes, gráficos, logotipos y diseños, son propiedad
              exclusiva de Daniel Ospid. Todos los derechos están reservados.
            </p>
            <p>
              <b>Uso del sitio web: </b>
              Está autorizado a usar este sitio web únicamente con fines
              informativos y de entretenimiento. No se permite la reproducción,
              distribución o modificación del contenido del sitio web sin el
              consentimiento previo y por escrito de Daniel Ospid.
            </p>
            <p>
              <b>Contenido del sitio web: </b>
              Daniel Ospid se esfuerza por mantener la precisión y la integridad
              del contenido de este sitio web, pero no garantiza la exactitud,
              la fiabilidad o la integridad del mismo. El contenido del sitio
              web puede cambiar sin previo aviso.
            </p>
            <p>
              <b>Responsabilidad del usuario: </b>
              Al utilizar este sitio web, eres responsable de garantizar que el
              contenido que publiques o envíes a través del sitio web sea
              preciso, legal y no infrinja los derechos de terceros.
            </p>
            <p>
              <b>Enlaces a otros sitios web: </b>
              Este sitio web puede incluir enlaces a otros sitios web que no
              están bajo el control de Daniel Ospid. No somos responsables del
              contenido de estos sitios web y no respaldamos ni garantizamos la
              precisión de la información que se encuentra en ellos.
            </p>
            <p>
              <b>Limitación de responsabilidad: </b>
              En la medida máxima permitida por la ley, Daniel Ospid no será
              responsable de ningún daño directo, indirecto, especial,
              incidental o consecuente que surja del uso o la imposibilidad de
              uso de este sitio web o de cualquier información o contenido
              disponible en el mismo.
            </p>
            <p>
              Al utilizar este sitio web, estas aceptando estos términos y
              condiciones en su totalidad. Si no está de acuerdo con estos
              términos y condiciones, no utilice este sitio web.
            </p>
            <p>
              <b>Última actualización:</b> 08 de julio de 2024
            </p>
          </article>
        </div>
      </section>
    </>
  );
}
export default Terms;
