import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

function Checkout(props) {
  const handleApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      alert("Transacción realizada por " + details.payer.name.given_name);
      window.location.href = props.download;
    });
  };

  return (
    <PayPalScriptProvider
      options={{
        "client-id":
          "AS8ALZFAaV0ko7iMqUgcnt_OJqgSVM85hSXZHUs8AQL65jLJNqPaTMFJQVZ8QqZp4kmgTJ-tCdAztL1W",
      }}
    >
      <PayPalButtons
        style={{
          color: "blue",
          layout: "vertical",
          shape: "rect",
        }}
        className="checkout"
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: props.price,
                },
              },
            ],
          });
        }}
        onApprove={handleApprove}
      />
    </PayPalScriptProvider>
  );
}

export default Checkout;
