import NavMenu from "./NavMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import useIsAdmin, { useLinks } from "../../firebase/fbQueries";
import { useTranslation } from "react-i18next";
import { db } from "../../firebase/config";
import { Link } from "react-router-dom";

function Menu(props) {
  const links = useLinks(db);
  const { t } = useTranslation();
  const isAdmin = useIsAdmin(db);

  return (
    <div className={!props.menu ? "menu" : "menu show"}>
      {props.menu && (
        <div>
          <div className="menu-header">
            <div className="menu-title">{t("menu.title")}</div>
          </div>
          <div className="menu-navigator">
            <NavMenu click={props.handleMenu} />
            <nav className="nav-menu">
              <ul className="nav-list">
                <li className="nav-item" onClick={props.handleMenu}>
                  <Link to="/subscribe" className="nav-link">
                    Suscríbete
                  </Link>
                </li>
                {isAdmin && (
                  <>
                    <li className="nav-item hidden" onClick={props.handleMenu}>
                      <Link to="/blog" className="nav-link">
                        Blog
                      </Link>
                    </li>
                    <li className="nav-item hidden" onClick={props.handleMenu}>
                      <Link to="/playlists" className="nav-link">
                        Listas
                      </Link>
                    </li>
                    <li className="nav-item hidden" onClick={props.handleMenu}>
                      <Link to="/photos" className="nav-link">
                        Fotos
                      </Link>
                    </li>
                    <li className="nav-item hidden" onClick={props.handleMenu}>
                      <Link to="/lyrics" className="nav-link">
                        Letras
                      </Link>
                    </li>
                    <li className="nav-item hidden" onClick={props.handleMenu}>
                      <Link to="/tours" className="nav-link">
                        Conciertos
                      </Link>
                    </li>
                    <li className="nav-item hidden" onClick={props.handleMenu}>
                      <Link to="/admin" className="nav-link">
                        Panel de Control
                      </Link>
                    </li>
                  </>
                )}
                {links
                  .sort((a, b) => a.order - b.order)
                  .map((item, index) => (
                    <>
                      {item.show_link && item.text && item.link && (
                        <li key={index} className="nav-item">
                          <a
                            href={
                              item.link.startsWith("http://")
                                ? item.link.replace("http://", "https://")
                                : item.link.startsWith("https://")
                                ? item.link
                                : "https://" + item.link
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            className="nav-link"
                          >
                            {item.text}
                            <FontAwesomeIcon
                              icon={faArrowUpRightFromSquare}
                              size="sm"
                              title="Nueva pestaña"
                            />
                          </a>
                        </li>
                      )}
                    </>
                  ))}
              </ul>
            </nav>
          </div>
        </div>
      )}
    </div>
  );
}
export default Menu;
